import { CircularProgress } from '@mui/material'

export const Loading = ({ thickness = 4, size = 32, spinnerStyles, color, ...props }) => {
  return (
    <CircularProgress
      {...props}
      thickness={thickness}
      size={size}
      sx={{ color: (theme) => color || theme.palette.grey.light, ...spinnerStyles }}
    />
  )
}
