import { SvgIcon } from '@mui/material'

const IconMetrics = ({ size }) => {
  return (
    <SvgIcon style={{ fontSize: `${size}px` }}>
      <path d="M5.41353 10.6887C5.41353 9.97055 5.99378 9.38834 6.70956 9.38834C7.42533 9.38834 8.00558 9.97055 8.00558 10.6887V18.9699C8.00558 19.6881 7.42533 20.2703 6.70956 20.2703C5.99378 20.2703 5.41353 19.6881 5.41353 18.9699V10.6887Z" />
      <path d="M11.4441 5.03014C11.4441 4.31194 12.0243 3.72973 12.7401 3.72973C13.4559 3.72973 14.0362 4.31194 14.0362 5.03014V18.9699C14.0362 19.6881 13.4559 20.2703 12.7401 20.2703C12.0243 20.2703 11.4441 19.6881 11.4441 18.9699V5.03014Z" />
      <path d="M17.4747 14.6062C17.4747 13.888 18.0549 13.3058 18.7707 13.3058C19.4865 13.3058 20.0667 13.888 20.0667 14.6062V18.9699C20.0667 19.6881 19.4865 20.2703 18.7707 20.2703C18.0549 20.2703 17.4747 19.6881 17.4747 18.9699V14.6062Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2151 5.03014C11.2151 4.18507 11.8979 3.5 12.7401 3.5C13.5824 3.5 14.2651 4.18507 14.2651 5.03014V18.9699C14.2651 19.8149 13.5824 20.5 12.7401 20.5C11.8979 20.5 11.2151 19.8149 11.2151 18.9699V5.03014ZM12.7401 3.95946C12.1508 3.95946 11.6731 4.43882 11.6731 5.03014V18.9699C11.6731 19.5612 12.1508 20.0405 12.7401 20.0405C13.3295 20.0405 13.8072 19.5612 13.8072 18.9699V5.03014C13.8072 4.43882 13.3295 3.95946 12.7401 3.95946ZM5.18457 10.6887C5.18457 9.84367 5.86733 9.15861 6.70956 9.15861C7.55178 9.15861 8.23454 9.84367 8.23454 10.6887V18.9699C8.23454 19.8149 7.55178 20.5 6.70956 20.5C5.86733 20.5 5.18457 19.8149 5.18457 18.9699V10.6887ZM6.70956 9.61807C6.12023 9.61807 5.64248 10.0974 5.64248 10.6887V18.9699C5.64248 19.5612 6.12023 20.0405 6.70956 20.0405C7.29888 20.0405 7.77663 19.5612 7.77663 18.9699V10.6887C7.77663 10.0974 7.29888 9.61807 6.70956 9.61807ZM17.2457 14.6062C17.2457 13.7612 17.9285 13.0761 18.7707 13.0761C19.6129 13.0761 20.2957 13.7612 20.2957 14.6062V18.9699C20.2957 19.8149 19.6129 20.5 18.7707 20.5C17.9285 20.5 17.2457 19.8149 17.2457 18.9699V14.6062ZM18.7707 13.5356C18.1814 13.5356 17.7036 14.0149 17.7036 14.6062V18.9699C17.7036 19.5612 18.1814 20.0405 18.7707 20.0405C19.36 20.0405 19.8378 19.5612 19.8378 18.9699V14.6062C19.8378 14.0149 19.36 13.5356 18.7707 13.5356Z"
      />
    </SvgIcon>
  )
}
export { IconMetrics }
