import { SvgIcon } from '@mui/material'

const IconExplorer = ({ size }) => {
  return (
    <SvgIcon style={{ fontSize: `${size}px` }}>
      <circle
        cx="12.3033"
        cy="12.0645"
        r="7.2891"
        stroke="currentColor"
        strokeWidth="2"
        fillOpacity="0"
      />
      <path
        d="M12.3032 2L12.3032 4.01273"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.3032 19.9883L12.3032 22.001"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22.5571 12.0645H20.5444"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.0127 12.0645L1.99997 12.0645"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M14.1008 12.0653C14.1008 13.0581 13.2959 13.863 12.3031 13.863C11.3102 13.863 10.5054 13.0581 10.5054 12.0653C10.5054 11.0724 11.3102 10.2676 12.3031 10.2676C13.2959 10.2676 14.1008 11.0724 14.1008 12.0653Z" />
    </SvgIcon>
  )
}
export { IconExplorer }
