import { Tooltip } from '@mui/material'
import MuiButton from '@mui/material/Button'
import { sendClickEvent } from '_app/utils/sendClickEvents'
import { Loading } from 'common/components/Loading'
import { isString, kebabCase } from 'lodash'
import { cloneElement, forwardRef, isValidElement } from 'react'
import { hotjar } from 'react-hotjar'
import { Link } from './Link'

/**
 * Provide 'icon' prop to add an icon to the button instead of using IconButton component
 * This icon will be positioned differently than start and end icons
 * Provide this icon as an object from lucide-react (or custom icons), not as a node element
 */
export const Button = forwardRef(
  (
    {
      children,
      loading,
      disabled,
      publishEventProps = {},
      onClick = () => {},
      to,
      isIconButton,
      size,
      startIcon,
      endIcon,
      disabledReason,
      tooltipMessage = '',
      ...props
    },
    outerRef
  ) => {
    const click = async (e) => {
      onClick(e)
      if (publishEventProps?.elementId && publishEventProps?.action) {
        await sendClickEvent({ publishEventProps })
      }
      /**
       * Track hotjar event on button click and add event name if passed as prop or use button text if a string
       */
      try {
        hotjar.event(
          publishEventProps?.event || isString(children) ? kebabCase(children) : 'button-click'
        )
      } catch (err) {}
    }

    const iconButtonSize = size === 'small' ? 13 : size === 'large' ? 17 : 15

    const button = (
      <MuiButton
        disabled={disabled || !!loading}
        ref={outerRef}
        onClick={!to ? click : undefined} // don't provide an on click if button is a link
        to={to} // used for button links
        component={to && Link} // if is button link then use Link component
        startIcon={
          loading && !isIconButton ? (
            <Loading size={iconButtonSize} thickness={4} style={{ color: 'currentColor' }} />
          ) : isValidElement(startIcon) ? (
            cloneElement(startIcon, { size: iconButtonSize })
          ) : null
        }
        endIcon={isValidElement(endIcon) ? cloneElement(endIcon, { size: iconButtonSize }) : null}
        size={size}
        {...props}
      >
        {isIconButton && loading ? (
          <Loading size={iconButtonSize} thickness={4} style={{ color: 'currentColor' }} />
        ) : isIconButton ? (
          cloneElement(children, { size: iconButtonSize })
        ) : (
          children
        )}
      </MuiButton>
    )
    if ((disabled && disabledReason) || tooltipMessage) {
      return (
        <Tooltip title={disabled && disabledReason ? disabledReason : tooltipMessage}>
          <span>{button}</span>
        </Tooltip>
      )
    }
    return button
  }
)

export default Button
