import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { router } from 'routes'
import * as serviceWorkerRegistration from './_app/utils/serviceWorkerRegistration'

/**
 * Render the app
 */

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<RouterProvider router={router} />)

serviceWorkerRegistration.unregister()
