import { Link as MuiLink } from '@mui/material'
import { sendClickEvent } from '_app/utils/sendClickEvents'
import { includes, isString, kebabCase, merge } from 'lodash'
import { ExternalLink } from 'lucide-react'
import { forwardRef } from 'react'
import { hotjar } from 'react-hotjar'
import { Link as RouterLink } from 'react-router-dom'

/**
 * Link component that handles internal and external links
 * It can use an 'onClick' event instead of a 'to' link
 */
export const Link = forwardRef(
  (
    {
      children,
      to,
      onClick,
      publishEventProps = {},
      sx = {},
      disabled,
      showIcon = false,
      openExternal = false,
      ...rest
    },
    outerRef
  ) => {
    const isExternal = includes(to?.pathname, 'https') || includes(to, 'https') || openExternal
    const link = isExternal ? { href: to, target: '_blank' } : { to }

    const click = async (e) => {
      e?.stopPropagation()
      if (onClick || !to) {
        e.preventDefault()
      }
      if (onClick) {
        onClick(e)
      }
      if (publishEventProps?.elementId && publishEventProps?.action) {
        await sendClickEvent()
      }
      /**
       * Track hotjar event on button click and add event name if passed as prop or use button text if a string
       */
      try {
        hotjar.event(
          publishEventProps?.event || isString(children) ? kebabCase(children) : 'button-click'
        )
      } catch (err) {}
    }
    return (
      <MuiLink
        ref={outerRef}
        component={isExternal ? undefined : RouterLink}
        onClick={click}
        underline="none" // can be 'none' or 'always'
        sx={merge(
          {
            /**
             * Disable link if 'disabled' prop is passed
             */
            pointerEvents: disabled ? 'none' : '',
            opacity: disabled ? 0.6 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            gap: 0.3,
            textDecorationColor: 'inherit',
          },
          sx
        )}
        {...(rest.target ? { rel: 'noreferrer' } : {})}
        {...link}
        {...rest}
      >
        {children}
        {showIcon && isExternal && <ExternalLink size={10} style={{ marginTop: 1 }} />}
      </MuiLink>
    )
  }
)
