import config from 'config'
import { SlsCoreEventsClient, WebEvents } from '@serverlessinc/core-events'

const { WebElementClickedV2Event } = WebEvents

export const sendClickEvent = async ({ publishEventProps = {} }) => {
  try {
    const localData = window.localStorage.getItem(config.localStorageKey)
    const storageData = JSON.parse(localData)
    if (
      storageData?.idToken?.trim() === '' ||
      storageData?.user?.userId?.trim() === '' ||
      storageData?.activeOrg?.orgId?.trim() === ''
    ) {
      return
    }
    const token = storageData?.idToken
    const userId = storageData?.user?.userId
    const orgId = storageData?.activeOrg?.orgId
    const slsCoreEventsClient = new SlsCoreEventsClient(config.platform.eventsUrl, token)
    const clickEvent = new WebElementClickedV2Event({
      orgId,
      userId,
      elementId: publishEventProps.elementId,
      action: publishEventProps.action,
      actionDetails: publishEventProps.actionDetails,
    })
    await slsCoreEventsClient.publish(clickEvent)
  } catch (error) {
    console.error(error)
  }
}
