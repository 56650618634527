import { Navigate, useLocation } from 'react-router-dom'

/**
 * A custom component that handles redirects while preserving the state search params
 */
export const Redirect = (props) => {
  const location = useLocation()
  return (
    <Navigate
      {...props}
      to={{
        pathname: props.to,
        search: location.search,
      }}
      replace
      state={{ from: location }}
    />
  )
}
